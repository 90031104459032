import { SearchLink } from '@graphcommerce/magento-search'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefaultProps,
  iconCustomerService,
  iconHeart,
  MenuFab,
  MenuFabSecondaryItem,
  IconSvg,
  DesktopNavItem,
  MenuFabItem,
  DarkLightModeMenuSecondaryItem,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Fab } from '@mui/material'
import { useRouter } from 'next/router'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'
import { CustomerMenuFabItem } from '../ChipLabCustom/Customer/CustomerMenuFabItem'
import { CustomerFab } from '../ChipLabCustom/Customer/CustomerFab'
import { CartFab } from '../ChipLabCustom/CartFab/CartFab'
import Link from 'next/link'
import { LayoutDefault } from 'components/ChipLabCustom/Layout/LayoutDefault'
import { PlaceholderFab } from 'components/ChipLabCustom/CartFab/PlaceholderFab'
import { useQuery } from '@graphcommerce/graphql'
import { RowPromoBannerDocument } from './RowPromoBanner.gql'
import BannerPromo from './BannerPromo'

export type LayoutFullProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutFull(props: LayoutFullProps) {
  const { footer, menu, children, ...uiProps } = props

  const router = useRouter()
  const menuItemsIncludeInMenu = menu?.items?.filter((items) => items?.include_in_menu === 1)
  const { data } = useQuery(RowPromoBannerDocument);
  const bannerActive = data?.rowPromoBanners?.[0]?.active ?? false;

  return (
    <>

      {bannerActive && <BannerPromo {...data?.rowPromoBanners?.[0]} />}
      <LayoutDefault
        {...uiProps}
        bannerActive={bannerActive}
        noSticky={router.asPath.split('?')[0] === '/'}
        header={

          <>
            <Logo />
            <DesktopNavBar>
              <DesktopNavItem href='/custom-poker-chips'>
                <Trans id='Custom Poker Chips' />
              </DesktopNavItem>
              {/* <DesktopNavItem href='/poker-sets'>
              <Trans id='Poker Sets' />
            </DesktopNavItem> */}
              {menuItemsIncludeInMenu?.map((item) => {
                const highLight = item?.name?.toLowerCase().includes('sale')
                  ? { textTransform: 'uppercase', letterSpacing: 0.3, color: 'primary.main' }
                  : {}
                return (
                  <DesktopNavItem key={item?.uid} href={`/${item?.url_path}`} sx={highLight}>
                    {item?.name}
                  </DesktopNavItem>
                )
              })}
              <DesktopNavItem href='/blog'>
                <Trans id='Blog' />
              </DesktopNavItem>
            </DesktopNavBar>
            <DesktopNavActions>
              {!router.pathname.startsWith('/search') && (
                <SearchLink href='/search' aria-label={i18n._(/* i18n */ 'Search...')} />
              )}
              <Link href='/service' style={{ textDecoration: 'none' }}>
                <Fab
                  aria-label={i18n._(/* i18n*/ `Account`)}
                  size='large'
                  color='inherit'
                >
                  <IconSvg src={iconCustomerService} size='large' />
                </Fab>
              </Link>
              <Link href='/wishlist' style={{ textDecoration: 'none' }}>
                <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
              </Link>
              <CustomerFab guestHref='/account/signin' authHref='/account' />
              {/* The placeholder exists because the CartFab is sticky but we want to reserve the space for the <CartFab /> */}
              <PlaceholderFab />
            </DesktopNavActions>

          </>
        }
        footer={<Footer footer={footer} />}
        cartFab={<CartFab />}
        menuFab={
          <MenuFab
            search={
              <SearchLink
                href='/search'
                sx={{ width: '100%' }}
                aria-label={i18n._(/* i18n */ 'Search...')}
              >
                <Trans id='Search...' />
              </SearchLink>
            }
            secondary={[
              <CustomerMenuFabItem key='account' guestHref='/account/signin' authHref='/account'>
                <Trans id='Account' />
              </CustomerMenuFabItem>,
              <MenuFabSecondaryItem
                key='service'
                icon={<IconSvg src={iconCustomerService} size='medium' />}
                href='/service'
              >
                <Trans id='Customer Service' />
              </MenuFabSecondaryItem>,
              <WishlistMenuFabItem key='wishlist' icon={<IconSvg src={iconHeart} size='medium' />}>
                <Trans id='Wishlist' />
              </WishlistMenuFabItem>,
              // <DarkLightModeMenuSecondaryItem key='darkmode' />,
            ]}
          >
            <MenuFabItem href='/'>
              <Trans id='Home' />
            </MenuFabItem>
            <MenuFabItem href='/custom-poker-chips'>
              <Trans id='Custom Poker Chips' />
            </MenuFabItem>
            {/* <MenuFabItem href='/poker-sets'>
            <Trans id='Poker Sets' />
          </MenuFabItem> */}
            {menuItemsIncludeInMenu?.map((item) => {
              const highLight = item?.name?.toLowerCase().includes('sale')
                ? { textTransform: 'uppercase', letterSpacing: 0.3, color: 'primary.main' }
                : {}
              return (
                <MenuFabItem key={item?.uid} href={`/${item?.url_path}`} sx={highLight}>
                  {item?.name}
                </MenuFabItem>
              )
            })}
            <MenuFabItem href='/blog'>
              <Trans id='Blog' />
            </MenuFabItem>
          </MenuFab>
        }
      >
        {children}
      </LayoutDefault>
    </>
  )
}
