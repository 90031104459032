import { PageOptions } from '@graphcommerce/framer-next-pages'
import { ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, LayoutHeader, MetaRobots, PageMeta } from '@graphcommerce/next-ui'
import { RowProduct, RowRenderer } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { DefaultPageDocument, DefaultPageQuery } from '../graphql/DefaultPage.gql'
import { InstagramPostsDocument } from '../graphql/InstagramPosts.gql'
import { ProductListWithReviewsDocument } from '../graphql/ProductListWithReviews.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'
import { LayoutFull, LayoutFullProps } from '../components/Layout/LayoutFull'
import { GetCustomReviewsDocument } from 'graphql/GetCustomReviews.gql'

type Props = DefaultPageQuery & {
  latestList: ProductListQuery
  favoritesList: ProductListQuery
  swipableList: ProductListQuery
  instagramPosts: any
  tokenData?: any
  aggregatedReviews?: any
}
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutFullProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, latestList, favoritesList, swipableList, instagramPosts, aggregatedReviews } = props
  const page = pages?.[0]

  const latest = latestList?.products?.items?.[0]
  const favorite = favoritesList?.products?.items?.[0]
  const swipable = swipableList?.products?.items?.[0]

  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      <LayoutHeader floatingMd floatingSm />

      {page && (
        <RowRenderer
          content={page.content}
          renderer={{
            RowProduct: (rowProps) => {
              const { identity } = rowProps

              if (identity === 'home-favorites')
                return (
                  <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />)
              if (identity === 'home-latest')
                return <RowProduct {...rowProps} {...latest} items={latestList.products?.items} />
              if (identity === 'home-swipable')
                return (
                  <RowProduct {...rowProps} {...swipable} items={swipableList.products?.items} />
                )
              return (
                <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} {...instagramPosts.data} aggregatedReviews={aggregatedReviews} />
              )
            },
          }}
        />
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutFull,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })

  const page = staticClient.query({ query: DefaultPageDocument, variables: { url: `page/home` } })
  const layout = staticClient.query({ query: LayoutDocument })

  // todo(paales): Remove when https://github.com/Urigo/graphql-mesh/issues/1257 is resolved
  const favoritesList = client.query({
    query: ProductListWithReviewsDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'ODE=' } }, categoryUid: 'ODE=' },
  })

  const latestList = staticClient.query({
    query: ProductListWithReviewsDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTAy' } }, categoryUid: 'MTIx' },
  })

  const swipableList = staticClient.query({
    query: ProductListWithReviewsDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTIy' } }, categoryUid: 'MTIx' }
  })
  const instagramPosts = client.query({
    query: InstagramPostsDocument,
    variables: { page: 1, limit: 10, sortField: 4 },
  })

  const aggregatedReviews = client.query({
    query: GetCustomReviewsDocument,
    variables: { pageSize: 10, currentPage: 1, productIds: "5056,7622" },
  })

  if (!(await page).data?.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      latestList: (await latestList).data,
      favoritesList: (await favoritesList).data,
      swipableList: (await swipableList).data,
      aggregatedReviews: (await aggregatedReviews).data,
      instagramPosts: await instagramPosts,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
